/**
 * Retrieves the correlation ID from the global `window` object.
 *
 * The correlation ID is used to trace the user's navigation history.
 * It is stored in `window.adobeMetrics.pageState.historyId`.
 *
 * @returns {string|undefined} The correlation ID if available, otherwise `undefined`.
 */
export function getCorrelationId() {
  return window.adobeMetrics?.pageState?.historyId
}
